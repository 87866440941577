<script>
  import FormBlock from './formBlock.svelte';
  import { fade } from 'svelte/transition';
  let numbers=2;

  function addCol() {
    numbers += 1;
  }

  function removeCol() {
    numbers -= 1;
  }

</script>

<style>button{background-color:#fff;color:#000;padding:.5rem 1rem;border-width:1px;border-color:#a0aec0;margin-top:.5rem;margin-bottom:.5rem}button:hover{background-color:#2d3748;color:#fff}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2NvbnRlbnRzLnN2ZWx0ZSIsIjxubyBzb3VyY2U+Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFLE9DREYsc0JBQUEsV0FBQSxtQkFBQSxpQkFBQSxxQkFBQSxpQkFBQSxtQkRHRSxDQUNBLGFDSkYseUJBQUEsVURNRSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9jb250ZW50cy5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgYnV0dG9uIHtcbiAgICBAYXBwbHkgYmctd2hpdGUgdGV4dC1ibGFjayBweS0yIHB4LTQgYm9yZGVyIGJvcmRlci1ncmF5LTUwMCBteS0yO1xuICB9XG4gIGJ1dHRvbjpob3ZlciB7XG4gICAgQGFwcGx5IGJnLWdyYXktODAwIHRleHQtd2hpdGU7XG4gIH1cblxuXG5cblxuIixudWxsXX0= */</style>

<div class="p-8 bg-white">

<h1 class="text-lg">Compare Internet plans</h1>

  <div class="block my-4">
    <button on:click={addCol} >
      Add a plan
    </button>
    <button on:click={removeCol} >
      Remove a plan
    </button>
  </div>

  <div class="p-auto overflow-auto flex flex-col sm:flex-row h-full">
    {#each { length: numbers } as _, i}
        <span transition:fade="{{ duration:500 }}" class="border w-full sm:min-w-1/2"><FormBlock/></span>
    {/each}
  </div>

</div>
